<template>
	<div class="page_content">
		<div class="form">
			<!-- <div class="info">Сколько запросов использовать</div>
			<div class="values">
				<div class="radio">
					<label>
						<span class="value">1</span>
						<input type="radio" name="queries" value="1" v-model="queries" />
					</label>
				</div>
				<div class="radio">
					<label>
						<span class="value">2</span>
						<input type="radio" name="queries" value="2" v-model="queries" />
					</label>
				</div>
				<div class="radio">
					<label>
						<span class="value">3</span>
						<input type="radio" name="queries" value="3" v-model="queries" />
					</label>
				</div>
			</div> -->
			<div class="input">
				<input type="text" v-model="code" placeholder="Введите код" />
			</div>
			<div class="button" @click="findProducts()">
				Отправить <span v-if="showProducts">({{ products.length }})</span>
			</div>
		</div>
		<!-- <div id="qr-reader" style="width: 600px"></div> -->
		<div class="products" v-if="showProducts">
			<a
				class="product"
				:key="product.position"
				v-for="product in products"
				:href="product.link"
				target="_blank"
				:class="{ last: product.last }"
			>
				<div class="image">
					<img :src="product.thumbnail" alt="" />
				</div>
				<div class="description">
					<span class="name">
						{{ product.title }}
					</span>
					<span class="rating">
						{{ product.rating }}
					</span>
					<span class="price">
						{{ product.price }}
					</span>
					<span class="advert" v-if="product.advert"> Реклама </span>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
//import Quagga from "quagga";
// import { Html5QrcodeScanner } from "html5-qrcode";
import axios from "axios";
export default {
	data() {
		return {
			code: "",
			queries: 1,
			requestSended: false,
			showCamera: true,
			showProducts: false,
			products: [],
			scanner: null
		};
	},

	mounted() {
		// this.scanner = new Html5QrcodeScanner("qr-reader", { fps: 10, qrbox: 250 });
		// this.scanner.render(this.onScanSuccess);
		// let code = 123;
		// let formData = new FormData();
		// formData.append("code", code);
		// axios
		// 	.post("https://nomera.sigma-studio.pp.ua/barcode/barcode.php", formData)
		// 	.then((response) => {
		// 		this.showCamera = false;
		// 		this.showProducts = true;
		// 		this.products = response.data;
		// 	});
		// Quagga.init(
		// 	{
		// 		inputStream: {
		// 			name: "Live",
		// 			type: "LiveStream",
		// 			target: document.querySelector(".camera") // Or '#yourElement' (optional)
		// 		},
		// 		decoder: {
		// 			readers: ["ean_8_reader"]
		// 		}
		// 	},
		// 	function (err) {
		// 		if (err) {
		// 			console.log(err);
		// 			return;
		// 		}
		// 		console.log("Initialization finished. Ready to start");
		// 		Quagga.start();
		// 	}
		// );
		// Quagga.onDetected((result) => {
		// 	if (!this.requestSended) {
		// 		alert("Считано");
		// 		this.requestSended = true;
		// 		let code = result.codeResult.code;
		// 		let formData = new FormData();
		// 		formData.append("code", code);
		// 		axios
		// 			.post(
		// 				"https://nomera.sigma-studio.pp.ua/barcode/barcode.php",
		// 				formData
		// 			)
		// 			.then((response) => {
		// 				alert("Получено");
		// 				if (response.data === "failure") {
		// 					alert("Не найден штрих код");
		// 					window.location.reload();
		// 					return;
		// 				}
		// 				this.showCamera = false;
		// 				this.showProducts = true;
		// 				this.products = response.data;
		// 				console.log(this.showProducts, this.products);
		// 				if (!this.products.length) {
		// 					alert("Не найдено товаров");
		// 				}
		// 			});
		// 	}
		// });
	},

	methods: {
		findProducts() {
			if (!this.code) {
				alert("Введите код");
				return;
			}
			//alert("Отправлено, ждёмс");
			let formData = new FormData();
			formData.append("code", this.code);
			formData.append("queries", this.queries);
			axios
				.post("https://nomera.sigma-studio.pp.ua/barcode/barcode.php", formData)
				.then((response) => {
					if (response.data === "failure") {
						alert("Не найден штрих код");
						window.location.reload();
						return;
					}
					this.showProducts = true;
					this.products = response.data;
					if (!this.products.length) {
						alert("Не найдено товаров");
					}
				});
		}

		// onScanSuccess(decodedText) {
		// 	alert(decodedText);
		// 	//console.log(`Code scanned = ${decodedText}`, decodedResult);
		// }
	}
};
</script>

<style lang="scss">
html,
body,
.page_content {
	min-height: 100vh;
}
body {
	margin: 0;
	padding: 0;
}
video {
	max-width: 100%;
}
.form {
	padding: 2em 1em;
	.values {
		display: flex;
		margin: 1em -0.5em 0;
	}
	.radio {
		margin: 0 0.5em;
		.value {
			padding-right: 1em;
		}
		label {
			display: flex;
			align-items: center;
		}
		input {
			border: 1px solid white;
			border-radius: 50%;
			width: 1.5em;
			height: 1.5em;
			&:checked {
				background-color: white;
			}
		}
	}
	.input {
		margin-top: 1em;
		input {
			padding: 0.5em;
			border: 1px solid white;
			border-radius: 6px;
		}
	}
	.button {
		margin-top: 1em;
		padding: 0.5em 0;
		text-align: center;
		background-color: green;
		border-radius: 6px;
	}
}
.products {
	display: flex;
	flex-direction: column;
	padding: 2em 1em;
	.product {
		margin-bottom: 2em;
		&.last {
			border: 3px solid red;
		}
		.image {
			height: 20em;
			img {
				width: 100%;
				height: 100%;
				max-width: 100%;
				object-fit: cover;
			}
		}
		.description {
			margin-top: 0.5em;
			.rating {
				display: block;
				margin-top: 0.5em;
				color: yellow;
			}
			.price {
				display: block;
				margin-top: 0.5em;
			}
			.advert {
				color: orange;
			}
		}
	}
}
@media (min-width: 768px) {
	.products {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		.product {
			width: 22%;
		}
	}
}
</style>
